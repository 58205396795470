import logo from './images/grey/android-chrome-512x512.png';
import './App.css';
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';

function App() {
  return (
    
    <div className="App">
      <header className="App-header">
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>Code building in progress</code>
          <br></br>Checkout my current portfolio site!
        </p>
        <Button variant = "contained" color = "secondary" href = "https://harshvardhan-singh.web.app">
          harshvardhan-singh.web.app
         </Button>

      </header>
    </div>
  );
}

export default App;
